<template>
  <div class="spinner-container">
    <b-spinner
      style="width: 3rem; height: 3rem; margin: 0 auto; display: block"
      variant="primary"
    />
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import axios from "@axios";

export default {
  components: {
    BSpinner,
  },
  mounted() {
    const { hash } = this.$route.query;
    this.handleNewRoute(hash);
  },
  methods: {
    async handleNewRoute(hash) {
      localStorage.clear();
      const response = await axios.put(
        `${process.env.VUE_APP_API}/verification/email/${hash}`
      );
      console.log("handleNewRoute", response);
      let url = "";
      if (response.data.data.url.includes(".club")) url = response.data.data.url.split(".club")[1];
      if (response.data.data.url.includes(".app")) url = response.data.data.url.split(".app")[1];
      window.location.href = `${window.location.origin}${url}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
